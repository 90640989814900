/* eslint-disable */
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/headerBanner'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));// WHY WE IMPORTED THIS HERE, I THINK THIS NOT USED
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/analytics/productsCrossDataManager'));
    processInclude(require('./selectCountrySwitcher'));
    processInclude(require('./components/analytics/events'));
    processInclude(require('./components/h1TagsRevision'));
});

require('base/thirdParty/bootstrap');
require('./components/spinner');
require('slick-carousel/slick/slick');
require('jquery-mask-plugin/dist/jquery.mask.min');
require('select2/dist/js/select2');
require('jquery-date-dropdowns/jquery.date-dropdowns.min');

